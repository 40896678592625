// Import Bootstrap and its default variables
#root,
.app-container,
html,
body {
  height: 100%;
}
.channel-list-settings {
  display: flex;
  justify-content: space-between;
}

a.dropdown-item {
  cursor: pointer;
}
/*.channels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}*/
.channels {
  padding: 0 20px;
  > div {
    position: relative;
  }
}
.channel-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.channel-list-settings {
  margin-bottom: 1rem;
}
#channel-list-container {
  flex-grow: 1;
}
.channels::-webkit-scrollbar-track-piece {
  background: transparent;
}